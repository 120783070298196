<template>
    <div>
        <page-header
            :title="$t('pages.autoBalanceTransfer.chooseLinkedCards.title')"
            show-back
            @onClose="closeCardSelection"
        />

        <div class="container">
            <loading-indicator
                v-show="loading"
                :title="$t('global.loadingMessage.loading')"
            />

            <div class="text-center fw-bold small">
                {{ $t('pages.autoBalanceTransfer.chooseLinkedCards.subTitle') }}
            </div>

            <div
                class="custom-control custom-checkbox mt-4"
                v-for="account in updatedActiveBalanceTransferAccounts"
                :key="account.accountId"
            >
                <input
                    type="checkbox"
                    :id="`application-form-checkbox_${account.accountId}`"
                    class="custom-control-input"
                    v-model="account.isAutoBalanceTransferEnabled"
                >
                <label
                    class="custom-control-label"
                    :for="`application-form-checkbox_${account.accountId}`"
                >
                    {{ account.accountName }}
                </label>
            </div>

            <div
                class="alert alert-warning text-start mt-2"
                v-if="errorMessage"
                role="alert"
            >
                {{ errorMessage }}
            </div>

            <div class="d-grid">
                <button
                    class="btn btn-block btn-primary mt-4"
                    :disabled="!updatesHaveBeenMade"
                    @click="saveAutoBalanceTransferUpdates"
                >
                    {{ $t('pages.autoBalanceTransfer.chooseLinkedCards.cta') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { mapActions, mapGetters } from 'vuex'
    import { logger } from '@/utils/logger'
    import isEqual from 'lodash/isEqual'
    import cloneDeep from 'lodash/cloneDeep'
    import { i18n } from '@/utils/i18n'
    import { navigation } from '@/mixins/navigation'

    export default {
        name: 'SelectRecurringBalanceTransferCards',
        components: {
            PageHeader,
            LoadingIndicator,
        },
        mixins: [navigation],
        data: function () {
            return {
                loading: false,
                updatedActiveBalanceTransferAccounts: [],
                errorMessage: null,
            }
        },
        computed: {
            ...mapGetters(['activeBalanceTransferAccountsEligibleForRecurrence']),
            updatesHaveBeenMade() {
                return !isEqual(this.initialActiveBalanceTransferAccounts, this.updatedActiveBalanceTransferAccounts)
            },
        },
        mounted: function () {
            this.initialActiveBalanceTransferAccounts = cloneDeep(this.activeBalanceTransferAccountsEligibleForRecurrence)
            this.updatedActiveBalanceTransferAccounts = cloneDeep(this.activeBalanceTransferAccountsEligibleForRecurrence)
            logger.info(`Displaying ${this.updatedActiveBalanceTransferAccounts.length} active balance transfer accounts: ${JSON.stringify(this.updatedActiveBalanceTransferAccounts)}`)
            this.$logEvent('view_recurring_balance_transfer_cards', { accounts: this.initialActiveBalanceTransferAccounts })
        },
        methods: {
            ...mapActions(['updateAutoBalanceTransferEnabledForAccounts']),
            saveAutoBalanceTransferUpdates: function () {
                try {
                    this.$logEvent('click_button_submit_recurring_balance_transfer_cards', { accounts: this.updatedActiveBalanceTransferAccounts })
                    this.updateAutoBalanceTransferEnabledForAccounts(this.updatedActiveBalanceTransferAccounts)
                    this.closeCardSelection()
                } catch (e) {
                    this.errorMessage = i18n.t('pages.autoBalanceTransfer.errorMessage')
                    logger.error(`Couldn't update auto balance transfer enabled for accounts. Error: ${e.message}`)
                }
            },
            closeCardSelection: function () {
                if (this.isFromDeeplink()) {
                    this.returnToRoot()
                } else {
                    this.$router.go(-1)
                }
            },
        },
    }
</script>
